import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

export const OnboardingLayoutLottieContainer = ({ animationData }) =>
  animationData ? (
    <Lottie
      className='absolute lg:top-[unset] top-0 lg:w-screen sm:min-w-[900px] min-w-[700px] lg:bg-transparent bg-white'
      animationData={animationData}
      loop
    />
  ) : null;
