import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useGetStripeDetailsForOrgQuery, useSession } from '../../../../hooks';

export const OnboardingIntercomLayout = ({ children }) => {
  const { user, userId, organizationId } = useSession();
  const { boot, update } = useIntercom();
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  useEffect(() => {
    boot();
  }, []);
  useEffect(() => {
    if (!!userId?.length && !!user?.email?.length && user.createdAt) {
      update({
        email: user?.email,
        name: `${user?.name?.first} ${user?.name?.last}`,
        createdAt: new Date(user.createdAt).getTime(),
        userId,
        customAttributes: {
          organizationId,
          stripeSubscriptionId: stripeDetails?.subscriptionId,
        },
      });
    }
  }, [userId, user, stripeDetails]);
  return <div>{children}</div>;
};

export default OnboardingIntercomLayout;
