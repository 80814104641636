import { SimpleMenu } from 'ui';
import { UNITED_STATES } from '../../../../constants';

export const USStateSimpleMenu = ({ value, onChange, isError = false, onFocus, onBlur }) => (
  <SimpleMenu
    options={UNITED_STATES}
    placeholder='Select state'
    onChange={({ value }) => onChange(value)}
    selectedValue={value}
    isError={isError}
    onFocus={onFocus}
    onBlur={onBlur}
    autocomplete='state'
  />
);
