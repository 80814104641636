import React, { useEffect } from 'react';
import { useOnboardingIntercom } from './use-onboarding-intercom';
import {
  OnboardingLayoutContentContainer,
  OnboardingLayoutContentContainerProps,
} from './onboarding-layout-content-container';
import { useUpdateOnboardingStatusMutation } from '../../../../hooks/http/use-update-onboarding-status-mutation';
import { useRouter } from 'next/router';

type OnboardingLayoutProps = OnboardingLayoutContentContainerProps & {
  animationData?: unknown;
  hideBackButton?: boolean;
};

const skippedPages = ['/onboarding/new/create-account', '/onboarding/new/short-code', '/onboarding/new/workspace'];

export const OnboardingLayoutCentered = function OnboardingLayoutCenteredFn({
  children,
  ...rest
}: OnboardingLayoutProps) {
  const router = useRouter();
  const { mutateAsync: updateOnboardingStatus } = useUpdateOnboardingStatusMutation();
  useEffect(() => {
    if (!skippedPages.includes(router.pathname)) updateOnboardingStatus(router.pathname); // skip create-account page
  }, [router.pathname]);

  useOnboardingIntercom();
  return (
    <div className='flex flex-grow bg-white w-full h-[calc(100vh-68px)] overflow-auto justify-center'>
      <div className='bg-white w-[556px] mt-[56px] flex flex-col pl-3'>
        <OnboardingLayoutContentContainer {...rest}>{children}</OnboardingLayoutContentContainer>
      </div>
    </div>
  );
};
