import { CurrencyMenu, currencyOptions } from 'ui';

export const CurrencyPicker = ({
  value,
  onChange,
  'data-cy': dataCy,
  isError = false,
  onFocus,
  onBlur,
}: {
  value;
  onChange;
  'data-cy'?: string;
  isError?: boolean;
  onFocus?;
  onBlur?;
}) => (
  <CurrencyMenu
    onChange={({ value }) => onChange(value)}
    value={currencyOptions.find((item) => item.value?.toLowerCase() === value?.toLowerCase())}
    options={currencyOptions}
    data-cy={dataCy}
    isError={isError}
    onFocus={onFocus}
    onBlur={onBlur}
  />
);
