import { Button, PageHeader } from 'ui';
import { NextButton } from '../next-button';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';

export type OnboardingLayoutContentContainerProps = {
  children?: ReactNode;
  pageTitle: string;
  pageLogo?: ReactNode;
  pageDescription?: string;
  onNext?: () => any | Promise<any>;
  isNextLoading?: boolean;
  isNextDisabled?: boolean;
  isNextOptional?: boolean;
  onBack?: () => any | Promise<any>;
  isEnterPressDisabledForNext?: boolean;
  hideBackButton?: boolean;
  nextButtonLabel?: string;
};

export const OnboardingLayoutContentContainer = ({
  onBack,
  pageTitle,
  pageLogo,
  pageDescription,
  children,
  onNext,
  isNextLoading,
  isNextDisabled,
  isNextOptional,
  isEnterPressDisabledForNext,
  hideBackButton = false,
  nextButtonLabel = 'Next',
}: OnboardingLayoutContentContainerProps) => {
  const router = useRouter();
  const defaultOnBack = () => router.back();
  return (
    <>
      {onBack && (
        <div className='my-4'>
          <Button label='Go back' onClick={onBack} emphasis='low' />
        </div>
      )}
      <PageHeader title={pageTitle} description={pageDescription} logo={pageLogo} />
      <div className='px-6 pb-6'>
        {children}
        <div className='mt-12 flex items-center gap-x-3'>
          {!hideBackButton && (
            <Button
              className='rounded-full px-4 w-fit'
              label='Back'
              onClick={onBack ?? defaultOnBack}
              emphasis='medium'
            />
          )}
          {onNext && (
            <NextButton
              onClick={onNext}
              isLoading={isNextLoading}
              disabled={isNextDisabled}
              isOptional={isNextOptional}
              isEnterPressDisabled={isEnterPressDisabledForNext}
              label={nextButtonLabel}
            />
          )}
        </div>
      </div>
    </>
  );
};
