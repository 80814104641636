import React, { useEffect } from 'react';
import { useOnboardingIntercom } from './use-onboarding-intercom';
import {
  OnboardingLayoutContentContainer,
  OnboardingLayoutContentContainerProps,
} from './onboarding-layout-content-container';
import { OnboardingLayoutLottieContainer } from './onboarding-layout-lottie-container';
import { useUpdateOnboardingStatusMutation } from '../../../../hooks/http/use-update-onboarding-status-mutation';
import { useRouter } from 'next/router';

type OnboardingLayoutProps = OnboardingLayoutContentContainerProps & {
  animationData?: unknown;
  hideBackButton?: boolean;
};

const skippedPages = ['/onboarding/new/create-account', '/onboarding/new/short-code', '/onboarding/new/workspace'];

export const OnboardingLayout = function OnboardingLayoutFn({
  children,
  animationData,
  ...rest
}: OnboardingLayoutProps) {
  const router = useRouter();
  const { mutateAsync: updateOnboardingStatus } = useUpdateOnboardingStatusMutation();
  useEffect(() => {
    if (!skippedPages.includes(router.pathname)) updateOnboardingStatus(router.pathname); // skip create-account page
  }, [router.pathname]);

  useOnboardingIntercom();
  return (
    <div className='flex-grow h-screen bg-gray-100 overflow-hidden'>
      <div className='flex lg:flex-row flex-col lg:h-[calc(100vh-68px)] h-full w-full lg:overflow-hidden overflow-y-auto overflow-x-clip'>
        <div className='bg-white lg:w-2/5 w-full lg:min-h-full lg:overflow-auto lg:h-full h-fit flex flex-col pl-3'>
          <OnboardingLayoutContentContainer {...rest}>{children}</OnboardingLayoutContentContainer>
        </div>
        <div className='flex-grow lg:overflow-hidden h-full lg:bg-[#f3f4f6] bg-white relative flex justify-center items-center flex-grow'>
          <OnboardingLayoutLottieContainer animationData={animationData} />
        </div>
      </div>
    </div>
  );
};
