import { SimpleMenu } from 'ui';
import { ENTITY_TYPES } from '../../../../constants';

export const BusinessEntityTypeSimpleMenu = ({ value, onChange, isError = false, onFocus, onBlur }) => (
  <SimpleMenu
    options={ENTITY_TYPES}
    placeholder='Select business type'
    onChange={({ value }) => onChange(value)}
    selectedValue={value}
    isError={isError}
    onFocus={onFocus}
    onBlur={onBlur}
  />
);
