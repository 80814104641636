import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useGetStripeDetailsForOrgQuery, useSession } from '../../../../hooks';
import { useRouter } from 'next/router';

const blockedRoutesForStripeDetailsCall = [
  '/onboarding/new/create-account',
  '/onboarding/new/short-code',
  '/onboarding/new/workspace',
  '/login',
];

export const useOnboardingIntercom = () => {
  const { user, userId, organizationId } = useSession();
  const { boot, update } = useIntercom();
  const router = useRouter();
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery(
    !!blockedRoutesForStripeDetailsCall.find((path) => router.pathname.startsWith(path)),
  );
  useEffect(() => {
    boot();
  }, []);
  useEffect(() => {
    if (!!userId?.length && !!user?.email?.length && user.createdAt) {
      update({
        email: user?.email,
        name: `${user?.name?.first} ${user?.name?.last}`,
        createdAt: new Date(user?.createdAt).getTime(),
        userId,
        customAttributes: {
          organizationId,
          stripeSubscriptionId: stripeDetails?.subscriptionId,
        },
      });
    }
  }, [userId, user, stripeDetails]);
};
