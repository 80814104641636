import { useMemo } from 'react';
import { SimpleMenu } from 'ui';
import countryList from 'react-select-country-list';

export const CountrySimpleMenu = ({
  value,
  onChange,
  'data-cy': dataCy,
  menuPlacement,
  isError = false,
  onFocus,
  onBlur,
}: {
  value;
  onChange;
  'data-cy'?: string;
  menuPlacement?;
  isError?: boolean;
  onFocus?;
  onBlur?;
}) => {
  const options = useMemo(() => countryList().getData(), []);
  return (
    <SimpleMenu
      data-cy={dataCy}
      options={options}
      placeholder='Select country'
      onChange={({ value }) => onChange(value)}
      selectedValue={value}
      menuPlacement={menuPlacement}
      isError={isError}
      onFocus={onFocus}
      onBlur={onBlur}
      autocomplete='country'
    />
  );
};
