import { SimpleMenu } from 'ui';
import { useLegalEntities } from '../../../../hooks';
import { getDisplayedLegalEntities, mergeLegalEntities } from '../../../templates/utils';
import { useEffect, useMemo } from 'react';

export const LegalEntitySimpleMenu = ({ value, onChange, disabled = false, isLoading = false }) => {
  const { data: legalEntities, isLoading: isLoadingLegalEntities } = useLegalEntities();
  const displayedLegalEntities = useMemo(() => {
    const mergedLegalEntities = mergeLegalEntities(legalEntities);
    return getDisplayedLegalEntities(mergedLegalEntities);
  }, [legalEntities]);

  useEffect(() => {
    if (displayedLegalEntities.length === 1) onChange(displayedLegalEntities[0]);
  }, [displayedLegalEntities]);

  return (
    <SimpleMenu
      options={displayedLegalEntities}
      onChange={onChange}
      selectedValue={value}
      disabled={displayedLegalEntities.length === 1 || disabled}
      isLoading={isLoading || isLoadingLegalEntities}
    />
  );
};
