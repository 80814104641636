import React, { useEffect } from 'react';
import { Button } from 'ui';

export const NextButton = ({
  onClick,
  isLoading,
  disabled = false,
  isOptional,
  isEnterPressDisabled = false,
  label = 'Next',
}: {
  onClick?;
  isLoading?: boolean;
  disabled?: boolean;
  isOptional?: boolean;
  isEnterPressDisabled?: boolean;
  label?: string;
}) => {
  useEffect(() => {
    const keyDownHandler = (event) => {
     // console.log('User pressed: ', event.key);
      if (disabled || isLoading || isEnterPressDisabled) return;
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!disabled)
          // 👇️ your logic here
          onClick();
        else console.log('disabled');
        console.log(onClick);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onClick, disabled, isLoading, isEnterPressDisabled]);

  if (isLoading)
    return (
      <div className='flex gap-x-4 items-center'>
        <Button label='Loading' className='rounded-full px-4 w-fit' onClick={onClick} isLoading disabled />
        {!isLoading && (
          <p>
            press <span className='font-medium'>Enter ↵</span>
          </p>
        )}
      </div>
    );
  if (disabled)
    return (
      <div className='flex gap-x-4 items-center'>
        <Button label={label} className='rounded-full px-4 w-fit' onClick={onClick} disabled isLoading={isLoading} />
        <p>
          press <span className='font-medium'>Enter ↵</span>
        </p>
      </div>
    );
  return (
    <div className='flex gap-x-4 items-center'>
      <Button label={isOptional ? 'Skip' : label} className='rounded-full px-4 w-fit' onClick={onClick} />
      <p>
        press <span className='font-medium'>Enter ↵</span>
      </p>
    </div>
  );
};
